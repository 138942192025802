<template>
  <div class="container">
    <el-tabs value="1" @tab-click="tabClick">
      <el-tab-pane label="转让物品" name="1"></el-tab-pane>
      <el-tab-pane label="需求物品" name="2"></el-tab-pane>
    </el-tabs>

    <div>
      <el-button icon="el-icon-refresh" @click="onRefresh">刷新</el-button>
      <el-button type="danger" icon="el-icon-delete" @click="delAll">
        批量删除
      </el-button>
      <el-button
        v-loading="exportLoading"
        type="primary"
        icon="el-icon-upload2"
        @click="exportToExcel"
      >
        导出
      </el-button>
      <div class="mt10">
        <el-form
          ref="queryForm"
          :model="query"
          :inline="true"
          label-width="120px"
        >
          <el-form-item prop="name">
            <el-input
              v-model="query.name"
              placeholder="请输入姓名"
              class="handle-input mr10"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item prop="telephone">
            <el-input
              v-model="query.telephone"
              placeholder="请输入联系方式"
              class="handle-input mr10"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item prop="keyword">
            <el-input
              v-model="query.keyword"
              placeholder="请输入标题"
              class="handle-input mr10"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item prop="status">
            <el-select
              v-model="query.status"
              placeholder="请选择状态"
              class="handle-input mr10 qy-status"
              clearable
            >
              <el-option label="可交换" value="1"></el-option>
              <el-option label="交换中" value="2"></el-option>
              <el-option label="已完成" value="3"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="datearray">
            <el-date-picker
              v-model="query.datearray"
              value-format="yyyy-MM-dd"
              type="daterange"
              align="right"
              unlink-panels
              range-separator="至"
              start-placeholder="发布开始日期"
              end-placeholder="发布结束日期"
              :picker-options="pickerOptions"
              class="mr10"
            ></el-date-picker>
          </el-form-item>
          <el-form-item prop="isOut">
            <el-select
              v-model="query.isOut"
              placeholder="物品状态"
              class="qy-status"
              clearable
            >
              <el-option label="正常" value="1"></el-option>
              <el-option label="下架" value="0"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="typeId">
            <el-select v-model="query.typeId" placeholder="所属类别" clearable>
              <el-option
                v-for="item in typeList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" icon="el-icon-search" @click="search">
              搜索
            </el-button>
            <el-button icon="el-icon-reset" @click="resetForm('queryForm')">
              重置
            </el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>

    <!--数据列表-->
    <el-table
      v-loading="listLoading"
      :data="list"
      element-loading-text="正在加载 ..."
      border
      highlight-current-row
      fit
      align="center"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="expand">
        <template slot-scope="scope">
          <el-divider content-position="left">
            <el-tag type="success">详细清单</el-tag>
          </el-divider>
          <el-form label-position="left" inline class="demo-table-expand">
            <el-form-item label="姓名:">
              <span>{{ scope.row.name }}</span>
            </el-form-item>
            <el-form-item label="所属部门:">
              <span>{{ scope.row.department }}</span>
            </el-form-item>
            <el-form-item label="警号:">
              <span>{{ scope.row.policeCard }}</span>
            </el-form-item>
            <el-form-item label="电话号码:">
              <span>{{ scope.row.telephone }}</span>
            </el-form-item>
            <el-form-item label="备装类型:">
              <span>{{ scope.row.type }}</span>
            </el-form-item>
            <el-form-item label="备装尺码:">
              <span>{{ scope.row.size }}</span>
            </el-form-item>
            <el-form-item label="浏览量:">
              <span>{{ scope.row.hots }}</span>
            </el-form-item>
            <el-form-item label="浏览量:">
              <span>{{ scope.row.hots }}</span>
            </el-form-item>
            <el-form-item label="发布时间:">
              <span>{{ scope.row.publishTime }}</span>
            </el-form-item>
            <el-form-item label="交换时间:">
              <span>{{ scope.row.updateTime }}</span>
            </el-form-item>
            <el-form-item label="完成时间:">
              <span>{{ scope.row.completeTime }}</span>
            </el-form-item>
            <el-form-item label="描述:" style="width: 100%;">
              <span>{{ scope.row.description }}</span>
            </el-form-item>
          </el-form>
          <!--图片显示区域-->
          <template v-if="scope.row.imgUrlList.length > 0">
            <el-divider content-position="left">
              <el-tag>附件图片</el-tag>
            </el-divider>
            <el-row :gutter="20">
              <el-col
                v-for="imgItem in scope.row.imgUrlList"
                :key="imgItem.id"
                :span="6"
              >
                <el-image
                  style="width: 200px; height: 200px"
                  :src="imgItem"
                  :preview-src-list="scope.row.imgUrlList"
                ></el-image>
              </el-col>
            </el-row>
          </template>
        </template>
      </el-table-column>
      <el-table-column type="selection" width="55" />
      <el-table-column label="姓名" prop="name" width="120" />
      <el-table-column label="联系方式" prop="telephone" width="120" />
      <el-table-column label="发布时间" prop="publishTime" />
      <el-table-column label="交换时间" prop="updateTime" />
      <el-table-column label="完成时间" prop="completeTime" />
      <el-table-column label="上下架" prop="isOut" align="center" width="100">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.isOut === 0" type="success">下架</el-tag>
          <el-tag v-if="scope.row.isOut === 1">正常</el-tag>
        </template>
      </el-table-column>
      <el-table-column
        label="标题"
        prop="title"
        align="center"
        show-overflow-tooltip
      />
      <el-table-column label="类型" prop="type" />
      <el-table-column label="尺码" prop="size" width="100" />
      <el-table-column label="数量" prop="num" width="40" />
      <el-table-column label="状态" prop="status" align="center" width="80">
        <template slot-scope="scope">
          <font v-if="scope.row.status == 1" color="royalblue">可交换</font>
          <font v-if="scope.row.status == 2" color="deeppink">交换中</font>
          <font v-if="scope.row.status == 3" color="green">已完成</font>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="180" align="center">
        <template slot-scope="scope">
          <el-button type="primary" size="small" @click="openDialog(scope.row)">
            申请列表
          </el-button>
          <el-button type="danger" size="small" @click="onDel(scope.row)">
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <el-pagination
        :current-page="pageInfo.startPage"
        :page-sizes="[10, 20, 30, 50, 100, 200]"
        :page-size="pageInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pageInfo.total"
        @size-change="onSizeChange"
        @current-change="onPageChange"
      />
    </div>

    <!--弹出层对话框-->
    <el-dialog
      :title="'【' + dialogTitle + '】的用户交换信息'"
      :visible.sync="dialogTableVisible"
      width="90%"
      fullscreen
    >
      <el-table
        v-loading="dlgLoading"
        :data="exchangeData"
        element-loading-text="正在加载..."
        border
        max-height="50%"
      >
        <el-table-column type="expand">
          <template slot-scope="scope">
            <el-form label-position="left" inline class="demo-table-expand">
              <el-form-item label="发布时间:">
                <span>{{ scope.row.publishTime }}</span>
              </el-form-item>
              <el-form-item label="申请时间:">
                <span>{{ scope.row.updateTime }}</span>
              </el-form-item>
              <el-form-item label="交换时间:">
                <span>{{ scope.row.exchangeTime }}</span>
              </el-form-item>
              <el-form-item label="完成时间:">
                <span>{{ scope.row.completeTime }}</span>
              </el-form-item>
              <el-form-item label="浏览量:">
                <span>{{ scope.row.hots }}</span>
              </el-form-item>
              <el-form-item label="描述:" style="width: 100%;">
                <span>{{ scope.row.description }}</span>
              </el-form-item>
            </el-form>
            <template v-if="scope.row.imageList.length > 0">
              <el-divider content-position="left">
                <el-tag>附件图片</el-tag>
              </el-divider>
              <el-row :gutter="20">
                <el-col
                  v-for="imgItem in scope.row.imageList"
                  :key="imgItem.id"
                  :span="8"
                >
                  <el-popover
                    placement="top-start"
                    :title="imgItem.title"
                    width="50%"
                    trigger="click"
                  >
                    <el-image :src="imgItem.url"></el-image>
                    <el-image
                      slot="reference"
                      :src="imgItem.url"
                      style="max-width: 90%;height:240px;display:block"
                    ></el-image>
                  </el-popover>
                </el-col>
              </el-row>
            </template>
          </template>
        </el-table-column>
        <el-table-column
          property="userInfo.name"
          label="姓名"
        ></el-table-column>
        <el-table-column
          property="userInfo.department"
          label="所属部门"
        ></el-table-column>
        <el-table-column
          property="userInfo.policeCard"
          label="警号"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          property="userInfo.telephone"
          label="联系方式"
        ></el-table-column>
        <el-table-column
          property="updateTime"
          label="申请时间"
        ></el-table-column>
        <el-table-column property="type" label="备装类型"></el-table-column>
        <el-table-column property="size" label="备装尺寸"></el-table-column>
        <el-table-column
          property="title"
          label="标题"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column property="status" label="申请状态">
          <template slot-scope="scope">
            <font v-if="scope.row.status === 0" color="royalblue">申请中</font>
            <font v-if="scope.row.status === 1" color="green">接受</font>
            <font v-if="scope.row.status === 2" color="red">拒绝</font>
            <font v-if="scope.row.status === 3" color="gray">撤销</font>
          </template>
        </el-table-column>
        <el-table-column label="上下架" prop="isOut" align="center">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.isOut == 0" type="success">下架</el-tag>
            <el-tag v-if="scope.row.isOut == 1">正常</el-tag>
          </template>
        </el-table-column>
        <el-table-column
          property="publishOrNeed"
          label="物品来源"
          align="center"
        >
          <template slot-scope="scope">
            <el-tag v-if="scope.row.publishOrNeed == 1" type="success">
              发布
            </el-tag>
            <el-tag v-if="scope.row.publishOrNeed == 2">需求</el-tag>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
    <!--弹出层对话框-->
  </div>
</template>
<script>
import {
  getExchangeList,
  getApplyExchangeList,
  updateStatus,
  getParentTypeList,
  delExchange
} from '@/api/equipment';
import { export_json_to_excel } from '@/assets/js/Export2Excel';
import { Message, MessageBox } from 'element-ui';
import { constants } from 'fs';

export default {
  name: 'EquipmentExchange',
  data() {
    return {
      exchangeData: [],
      dlgLoading: false,
      dialogTableVisible: false,
      query: {
        name: '',
        telephone: '',
        keyword: '',
        publishOrNeed: 1,
        typeId: '',
        status: '',
        isOut: '',
        datearray: ''
      },
      pageInfo: {
        startPage: 1,
        pageSize: 50,
        total: 0,
        name: '',
        telephone: '',
        keyword: '',
        startDate: '',
        toDate: '',
        publishOrNeed: 1,
        status: -1,
        isOut: -1,
        typeId: ''
      },
      list: [],
      listLoading: true,
      selectDlgShow: false,
      exportLoading: false,
      multipleSelection: [],
      pickerOptions: {
        shortcuts: [
          {
            text: '最近一周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          },
          {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          },
          {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          }
        ]
      },
      typeList: [],
      dialogTitle: ''
    };
  },
  created() {
    this.fetchData();
    this.loadTypeList();
  },
  methods: {
    //加载衣服分类
    loadTypeList() {
      getParentTypeList().then(resp => {
        if (resp.code === 200) {
          this.typeList = resp.data;
        }
      });
    },
    tabClick(tab, event) {
      let publishOrNeed = tab.name;
      this.pageInfo.publishOrNeed = publishOrNeed;
      this.query.publishOrNeed = publishOrNeed;
      this.onRefresh();
    },
    onPageChange(page) {
      this.pageInfo.startPage = page;
      this.fetchData();
    },
    onSizeChange(size) {
      this.pageInfo.pageSize = size;
      this.fetchData();
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    search() {
      if (this.query.datearray != null) {
        const startDate = this.query.datearray[0];
        const toDate = this.query.datearray[1];
        this.pageInfo.startDate = startDate;
        this.pageInfo.toDate = toDate;
      } else {
        this.pageInfo.startDate = '';
        this.pageInfo.toDate = '';
      }
      this.pageInfo.name = this.query.name;
      this.pageInfo.status = this.query.status;
      this.pageInfo.telephone = this.query.telephone;
      this.pageInfo.keyword = this.query.keyword;
      this.pageInfo.publishOrNeed = this.query.publishOrNeed;
      this.pageInfo.isOut = this.query.isOut;
      this.pageInfo.typeId = this.query.typeId;
      this.fetchData();
    },
    onRefresh() {
      this.fetchData();
      this.loadTypeList();
    },
    openDialog(row) {
      this.dialogTitle = row.name;
      this.dialogTableVisible = true;
      this.dlgLoading = true;
      let applyForm = {
        infoId: row.id,
        userId: row.userid
      };
      getApplyExchangeList(applyForm).then(response => {
        this.exchangeData = response.data;
        this.dlgLoading = false;
      });
    },
    delAll() {
      this.$confirm('您确定要删除所选择的数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        var ids = [];
        this.multipleSelection.forEach(item => {
          ids.push(item.id);
        });
        delExchange({
          ids: ids
        }).then(resp => {
          if (resp.code == 200) {
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
            this.fetchData();
          } else {
            this.$message.error(resp.msg);
          }
        });
      });
    },
    onDel(row) {
      this.$confirm('此操作将永久删除此条数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        delExchange({
          id: row.id
        }).then(resp => {
          if (resp.code == 200) {
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
            this.fetchData();
          } else {
            this.$message.error(resp.msg);
          }
        });
      });
    },
    onUpdate(row) {
      this.$confirm('请确定已经完成备装互换流程，是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        var fromId = row.id;
        var toId = row.exchangid;
        updateStatus({
          fromId: fromId,
          toId: toId
        })
          .then(response => {
            if (response.code == 200) {
              this.$message({
                type: 'success',
                message: response.msg
              });
              this.fetchData();
            } else {
              this.$message.error('提交失败');
            }
          })
          .catch(() => {
            this.$message.error('提交失败');
          });
      });
    },
    fetchData() {
      this.listLoading = true;
      getExchangeList(this.pageInfo).then(response => {
        this.list = response.data.records;
        this.pageInfo.total = response.data.total;
        this.pageInfo.startPage = response.data.current;
        this.pageInfo.pageSize = response.data.size;
        this.listLoading = false;
      });
    },
    //excel数据导出
    exportToExcel() {
      this.exportLoading = true;
      require.ensure([], () => {
        const tHeader = [
          '姓名',
          '提交时间',
          '联系方式',
          '备装类型',
          '备装尺码',
          '数量'
        ];
        const filterVal = [
          'name',
          'publishTime',
          'telephone',
          'type',
          'size',
          'num'
        ];
        const list = this.list;
        const data = this.formatJson(filterVal, list);
        export_json_to_excel(tHeader, data, '备装互换信息');
        this.exportLoading = false;
      });
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => v[j]));
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    }
  }
};
</script>
<style scoped="scoped">
.handle-select {
  width: 120px;
}

.handle-input {
  width: 165px;
  display: inline-block;
}

.demo-table-expand {
  font-size: 0;
}

.demo-table-expand label {
  width: 90px;
  color: #99a9bf;
}

.demo-table-expand .el-form-item {
  margin-right: 0;
  margin-bottom: 0;
  width: 50%;
}
.el-dialog__body {
  padding: 10px 20px !important;
}
</style>
